import React from 'react';
import {
  ColorDataTableCode,
  PlatformTab,
  Section,
} from '../../../../components';

const ReactNativeTab = ({ data }) => {
  return (
    <PlatformTab>
      <Section title="Light Environment">
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="reactnative"
        />
      </Section>

      <Section title="Dark Environment">
        <ColorDataTableCode
          env="dark"
          colorsType="dark"
          data={data}
          platform="reactnative"
        />
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
