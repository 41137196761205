import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Environment = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Environment;
    return <Component {...props} />;
  },
});

const Level = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Level;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/environment.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{
          React: React,
          Environment: Environment,
          Level: Level,
          Text: Text,
        }}
        code={snippet}
        platform="react"
        gitHubLink="themes/environment"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>Content you want to apply the environment to.</Text>
          </PropListItem>

          <ClassnamePartial componentName="environment" />

          <EnvironmentPartial />
        </PropList>

        <PropList header="Miscallaneous">
          <QaIdPartial componentName="environment" />
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
